.articles-container {
	min-height: calc( 100vh - 70px ) ;
	background-color: blanchedalmond ;
	margin: 10px ;
}

.title {
	height: 2em !important ;
	font-size: 2em;
	font-weight: bold;
}

.articlesview-container {
	min-height: calc( 100vh - 70px ) ;
	background-color: lavenderblush ;
}

.articleedit-container {
	min-height: calc( 100vh - 70px ) ;
}

.article-sidebar {
	min-height: calc( 100vh - 70px ) ;
	padding: 60px ;
	background-color: lavenderblush ;
}

.article-card {
	border: 1px outset lightgrey;
	background-color: white ;
	padding: 16px 24px ;
}

.article-img {
	width: 100% ;
	object-fit: cover;
}

.article-img-overlay {
	opacity:'50%' ;
}

@media (min-width: 576px) {
	.article-img {
		height: 50vw ;
	}
}

@media (min-width: 768px) {
	.article-img {
		height: 30vw ;
	}
}

@media (min-width: 992px) {
	.article-img {
		height: 20vw ;
	}
}

@media (min-width: 1200px) {
	.article-img {
		height: 20vw ;
	}
}
