.tutorial-container {
	min-height: calc( 100vh - 140px ) ;
}

h2 a {
	color: black !important
}

figure img {
	padding: 10px ;
	border-style: solid ;
	border-width: 1px ;
	border-color: gray ;
}

p img {
	padding: 10px ;
	border-style: solid ;
	border-width: 1px ;
	border-color: gray ;
}

