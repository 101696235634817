.home-container {
	min-height: calc( 100vh - 140px ) ;
}

.home-background {
	height: 100vh ;
	width: 100vw ;
	padding: 0px !important;
}

.home-image {
	height: 100vh ;
	width: 100vw ;
}